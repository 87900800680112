import React from "react"
import styled from "styled-components"

import { Row, Col } from "react-bootstrap"
import ai from "../../assets/images/artificial-intelligence.svg"

const SmartModulesBox = () => {
  return (
    <Row className="g-0" className="homebox mx-auto">
      <Col md={12} lg={6}>
        <h2>Smart Modules</h2>
        <p>
          azszero Understands Amazon Business, It will save you a tremendous
          amount of time guessing and managing your Amazon stores by showing you
          what you need to see, Alerting you of What you need to know at that
          moment, and Telling you What to do next. It’s like a Super Teacher
          that looks overall your stores and gives you right to the point
          actions.
        </p>
      </Col>
      <Col md={12} lg={6} className="text-center">
        <ImgStyled src={ai} alt="" />
      </Col>
    </Row>
  )
}

const ImgStyled = styled.img`
  width: 250px;
  height: 250px;
`
export default SmartModulesBox
